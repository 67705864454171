





































































import { Component, Vue } from 'vue-property-decorator';
import Campaign from '@/shared/models/campaign.interface';
import LocaleSelection from '../components/LocaleSelection.vue';
import companySettings from '../$configs/settings.json';
import { Constants } from '@/constants';

@Component({
    name: 'CampaignOverview',
    components: { LocaleSelection },
})
export default class CampaignOverview extends Vue {
    private readonly locale: string;
    private readonly campaigns: Campaign[];
    private readonly currentDate: Date;
    private readonly campaignData: Campaign[];

    constructor() {
        super();
        this.currentDate = new Date();
        this.campaignData = [];
        this.locale = this.$parent.$route.params.locale;
        if (this.locale) {
            this.setLocale(this.locale);
        }

        //start the api call
        this.getCampaignOverview().then(data => {
            const parsedData = JSON.parse(data);
            const resultArray = [];
            for (let i = 0; i < parsedData.result.length; i++) {
                if (parsedData.result[i].localeDescription == this.locale) {
                    const ArrayObjects = parsedData.result[i];
                    resultArray.push(ArrayObjects);
                    this.campaignData.push(ArrayObjects);
                }
            }
        });

        //array with results
        this.campaigns = this.campaignData;
    }

    async getCampaignOverview() {
        const locale = this.$parent.$route.params.locale;
        const campaignOverviewRequest = await fetch(`/Api/GetCampaignOverview?currentLocale=${locale}`, { method: 'GET', headers: { 'Content-Type': 'application/json', }, credentials: 'include', redirect: 'follow', mode: 'same-origin' });
        const campaignOverviewResponse = await campaignOverviewRequest.json();

        return campaignOverviewResponse;
    }

    private setLocale(locale: string): void {
        // this.$parent.$i18n.locale = locale;
    }
    private setLocaleId(localeId: string): void {
        // this.$parent.$i18n.locale = locale;
    }

    private goToCampaign(campaignCode: number): void {
        this.$router.push(`/${this.locale}/registrations/${campaignCode}`);
    }

    //The addMonth function is now only used to parse the date/time in the correct format. 
    private addMonth(date: any) {
        const orginalDate = date.split(/[-+ :T]/);
        const newDate = new Date(orginalDate[0], orginalDate[1] - 1, orginalDate[2], orginalDate[3], orginalDate[4], orginalDate[5]);

        let yyyy = newDate.getFullYear();
        let mm = newDate.getMonth();// + 1;
        let dd = newDate.getDate();

        if (mm <= 11) {
            mm += 1;
        }
        else {
            mm = 1;
            dd = 1;
            yyyy += 1;
        }

        const dateNotation = yyyy + '/' + mm + '/' + dd;
        const goodDate = new Date(dateNotation);

        return goodDate;
    }
}
